
import { defineComponent, onMounted, onBeforeMount } from "vue";
import ToolbarFilter from "@/components/toolbar/ToolbarFilter.vue";
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useComponentv2 from '@/composables/v2/useComponent';
import Table from '@/components/tables/v2/Table.vue';
import Modal from "@/components/modals/default/v2/Modal.vue";
import Icon from '@/components/icons/Icon.vue';
import ModalFlow from "@/components/modals/default/v2/ModalShipmentLinesFlow.vue";

export default defineComponent({
  name: '',
  components: {
    ToolbarFilter,
    ToolbarActions,
    Table,
    Icon,
    Modal,
    ModalFlow
  },
  props: {
    currentId: String
  },
  setup (props, { emit }) {
    const { initComponent, componentData, componentRegisterId, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, reloadComponent, test, renderModal, renderAmount, renderId } = useComponentv2();
    
    initComponent({
      componentName: "ReturnsReturnPaymentsTable", 
      displayMethod: "existing", /* new or existing */
      componentType: "table", /* form or table */
      dispatchActions: { init: "PAYMENTS", save: "PAYMENT_EDIT", params: { init: false, key: "", value: ""}},
      componentReload: true,
      componentRedirect: { init: false, data: "", path: ""},
      currentData: "allPayments",
      componentFilter: { init: false, get: "currentLabelsFilter" },
      componentToolbar: { init: true, filter: false, actions: { init: true, title: "Payments", menu: [ { label: "Create", target: "#es_modal_returns_return_transactions_create" } ] } },
      currentId: props.currentId
    });

    onMounted(() => {
      MenuComponent.reinitialization();
    })

    return {
      componentData,
      componentRegisterId,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      reloadComponent,
      test,
      renderModal, renderAmount, renderId
    }
  }
});
