import { defineComponent, ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

export default function useShipmentLines() {
  const { emit } = getCurrentInstance() as any;
  const store = useStore();
  const initSettings = ref();
  const componentSettings = ref();
  const componentData = ref({ status: false, data: null, columns: null });
  const transferData = ref();
  const transferStatus = ref(false);
  const loadingButton = ref(false);

  const getComponentBuilder = (name, dataSet?) => {
    const component = store.getters.getComponents.find(e => e.name === name);
    let data;

    if (dataSet != null) {
      data = component[dataSet]
    } else {
      data = component
    }

    try {  
      data = JSON.parse(data);  
    } catch (e) {  
      return data;
    }

    return data;
  }

  const getFilterTemplate = (filters) => {
    let filterString = '?'

    if (filters != null) {
      const filterSettings = JSON.parse(filters)

      for (let i = 0; i < filterSettings.length; i++) { 
        const filterLine = filterSettings[i]
        let value;

        if (filterLine.type == 'id') {
          value = initSettings.value.currentId
        } else if (filterLine.type == 'routeData') {
          value = store.getters[filterLine.value][filterLine.field]
        } else {
          value = filterLine.value
        }

        filterString += '&filter=' + filterLine.field + ':' + filterLine.operator + ':' + value
      }

    }
    return filterString
  }

  const getDispatchData = async (endpoint, dataModule, filters) => {
    const filterString = await getFilterTemplate(filters)

    const data = store.dispatch(Actions[endpoint], filterString)
      .then(() => {
        return store.getters[dataModule].items
      })
      .catch(() => {
        return 
      })
    return data;
  }

  const gateway = async (test) => {
    transferData.value = await createTransferData(componentData.value.data, initSettings.value.currentId, initSettings.value.dispatchActions.save.payloadConfig)
  }

  const reloadComponent = () => {
    initComponent(initSettings.value)
  }

  const getDataTemplate = async (data, calculationMethod) => {
    const template = [] as any

    for (let i = 0; i < data.length; i++) {
      const field = {} as any

      field.id = { defaultValue: data[i].id, options: null }
      field.product_id = { defaultValue: data[i].product_id, options: null }
      field.sale_id = { defaultValue: data[i].sale_id, options: null }
      field.name = { defaultValue: data[i].name, options: null }
      field.quantity = { defaultValue: '', options: Array(parseInt(data[i][calculationMethod.start]) - parseInt(data[i][calculationMethod.end])).fill('').map((_, idx) => { return { value: idx + 1, label: idx + 1} } ).reverse() }

      // Add field to template
      template.push(field)
    }
    return template
  }
  
  const initComponent = async (settings) => {
    // Set component settings
    initSettings.value = settings
    componentSettings.value = await getComponentBuilder(settings.componentName)

    const template = {} as any;

    const externalData = await getDispatchData(settings.dispatchActions.init.endpoint, settings.dispatchActions.init.data, componentSettings.value.filters)

    template.columns = getComponentBuilder(settings.componentName, 'columns')
    template.data = await getDataTemplate(externalData, settings.calculationMethodField)
    template.status = true

    componentData.value = template
    transferData.value = createTransferData(componentData.value.data, settings.currentId, initSettings.value.dispatchActions.save.payloadConfig);
  }
  
  const createTransferData  = (data, currentId, payloadConfig) => {
    
    const template = {} as any
    let linesName;

    for (let i = 0; i < payloadConfig.length; i++) {
      const setting = payloadConfig[i];

      if (setting.key.includes('lines')) {
        linesName = setting.key;
        const lines = [] as any
        for (let i = 0; i < data.length; i++) {
          if (data[i].quantity.defaultValue > 0) {
          const line = {} as any

          line.id = data[i].id.defaultValue
          line.quantity = data[i].quantity.defaultValue
          
          lines.push(line)
          }
        }

        template[setting.key] = lines 
      } else {
        template[setting.key] = setting.value 
      }
    }

    // Set Submit button
    transferStatus.value = linesName != undefined ? template[linesName].length ? true : false : false

    return template
  }

  const sentTransfer = () => {
    loadingButton.value = true
    transferStatus.value = false
    const dispatchAction = initSettings.value.dispatchActions

    store.dispatch(Actions[dispatchAction.save.endpoint], { routeId: '', params: transferData.value } ).then(async() => {

      // Clear transfer data
      transferData.value = '' /* Clear data */

      if (dispatchAction.save.reload) {
        const modal = document.getElementById(initSettings.value.currentModalId)
        const modalObject = Modal.getOrCreateInstance(modal)
        modalObject.toggle()

        emit('reload')
      }
    }).catch((error) => {
      Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
              confirmButton: "btn btn-primary",
              },
          });
          return false;
    }).finally(() => {
      loadingButton.value = false
      transferStatus.value = false
    })
  }
  
  return {
    initComponent,
    componentData,
    gateway,
    transferData,
    transferStatus,
    sentTransfer,
    reloadComponent,
    loadingButton
  }
}