import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card shadow-sm mb-5 mb-xxl-8" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = ["data-bs-target", "aria-controls"]
const _hoisted_4 = {
  key: 0,
  class: "fw-bolder m-0"
}
const _hoisted_5 = {
  key: 0,
  class: "card-toolbar"
}
const _hoisted_6 = {
  key: 1,
  class: "me-0"
}
const _hoisted_7 = {
  class: "btn btn-sm btn-icon btn-bg-light btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2 svg-icon-gray-500" }
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "card-body p-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolbarFilter = _resolveComponent("ToolbarFilter")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ToolbarActions = _resolveComponent("ToolbarActions")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ModalFlow = _resolveComponent("ModalFlow")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "card-title m-0 flex-fill cursor-pointer",
        role: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": '#es_card_'+ _ctx.componentRegisterId,
        "aria-expanded": "true",
        "aria-controls": 'es_card_'+ _ctx.componentRegisterId
      }, [
        (_ctx.componentStatus)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.componentData.structure.title), 1))
          : _createCommentVNode("", true)
      ], 8, _hoisted_3),
      (_ctx.componentData.status && _ctx.componentData.toolbar.init)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.componentData.toolbar.filter)
              ? (_openBlock(), _createBlock(_component_ToolbarFilter, {
                  currentData: "allLabels",
                  dispatchActions: { init: "currentLabelsFilter", save: "SET_LABELS_FILTER" },
                  key: _ctx.reloadAmount,
                  onReload: _ctx.reloadComponent
                }, null, 8, ["onReload"]))
              : _createCommentVNode("", true),
            (_ctx.componentData.toolbar.actions.init)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("button", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, [
                      _createVNode(_component_Icon, { name: "Dots.svg" })
                    ])
                  ]),
                  _createVNode(_component_ToolbarActions, {
                    title: _ctx.componentData.toolbar.actions.title,
                    menu: _ctx.componentData.toolbar.actions.menu,
                    onReload: _ctx.reloadComponent
                  }, null, 8, ["title", "menu", "onReload"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "card-content collapse show",
      id: 'es_card_'+ _ctx.componentRegisterId
    }, [
      _createElementVNode("div", _hoisted_10, [
        (_ctx.componentData.status)
          ? (_openBlock(), _createBlock(_component_Table, {
              key: 0,
              "processing-data": _ctx.componentData,
              onUnique: _ctx.renderModal,
              onReload: _ctx.reloadComponent
            }, null, 8, ["processing-data", "onUnique", "onReload"]))
          : _createCommentVNode("", true),
        (_ctx.renderAmount)
          ? (_openBlock(), _createBlock(_component_Modal, {
              key: _ctx.renderAmount,
              onReload: _ctx.reloadComponent,
              componentName: "ReturnsReturnPaymentsTable",
              componentTitle: "Payments",
              displayMethod: "existing",
              componentType: "form",
              dispatchActions: { init: "PAYMENT", save: "PAYMENT_EDIT" , params: { init: false, key: "", value: ""} },
              componentReload: true,
              componentRedirect: { init: false, data: "", path: "item_id"},
              currentData: "currentPayment",
              componentFilter: false,
              modalId: "es_modal_returns_return_transactions_transaction_edit",
              currentId: _ctx.renderId
            }, null, 8, ["onReload", "currentId"]))
          : _createCommentVNode("", true),
        (_ctx.componentData.status)
          ? (_openBlock(), _createBlock(_component_ModalFlow, {
              key: _ctx.reloadAmount,
              onReload: _ctx.reloadComponent,
              componentName: "ReturnsReturnPaymentsAcceptedLinesTable",
              dispatchActions: {  init: { type: "get", status: true, endpoint: "RETURN_RECEIVED_LINES_UNREFUNDED", data: "allReturnReceivedLines" }, 
                          save: { status: true, endpoint: "RETURN_TRANSACTION_CREATE", reload: true, payloadConfig: [ { key: "received_lines", value: [] }, { key: "return_id", value: _ctx.currentId } ] } },
              componentReload: true,
              componentRedirect: { init: false, data: "", path: ""},
              currentData: "currentReturnReceivedLine",
              componentFilter: false,
              calculationMethodField: { start: "quantity", end: "refund_quantity"},
              currentId: _ctx.currentId,
              modalId: "es_modal_returns_return_transactions_create"
            }, null, 8, ["onReload", "dispatchActions", "currentId"]))
          : _createCommentVNode("", true)
      ])
    ], 8, _hoisted_9)
  ])), [
    [_directive_loading, _ctx.componentLoading]
  ])
}