<template>
  <el-table
    ref="multipleTable"
    :data="processingData.data"
    style="width: 100%"
  >
    <el-table-column v-for="itemIn in processingData.columns" :key="itemIn.field"
      :property="itemIn.field"
      :label="itemIn.title"
      :width="itemIn.width"
    >
      <template #default="scope">
        <template v-if="itemIn.type == 'text'">
          {{ scope.row[itemIn.field].defaultValue }}
        </template>
        
        <template v-else-if="itemIn.type == 'simpleLink'">
          <router-link :to="itemIn.options.route+ '/' +scope.row[itemIn.field][itemIn.options.link]">
              {{ scope.row[itemIn.field].defaultValue }}
          </router-link>
        </template>

        <template v-else-if="itemIn.type == 'inputText'">
          <el-form label-position="top">
            <el-select v-model="scope.row[itemIn.field].defaultValue" clearable filterable placeholder="Choose" @change="updateField(scope.row, scope.row[itemIn.field].defaultValue)">
              <el-option
                v-for="item in scope.row[itemIn.field].options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form>
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ref } from 'vue';
import { defineComponent } from "vue";

export default defineComponent({
  name: "",
  props: {
    processingData: Object
  },
  components: {},
  setup(props, {emit}) {

    const updateField = (rowData, newValue) => {
      const productId = rowData.product_id.defaultValue
      emit('gateway', { action: 'update', productId: productId, currentValue: newValue })
    }
    
    return {
      updateField,
    };
  }
});
</script>
