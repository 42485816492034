
import { defineComponent, computed, ref, onMounted, nextTick, watch } from "vue";
import useShipmentLines from '@/composables/v2/flows/useShipmentLines';
import Table from '@/components/tables/v2/TableFlow.vue'

export default defineComponent({
  name: "",
  components: {
      Table
  },
  props: {
      componentName: String,
      componentTitle: String,
      displayMethod: String,
      componentType: String,
      dispatchActions: Object,
      componentEmit: Object,
      componentReload: Boolean,
      componentRedirect: Object,
      currentData: String,
      componentFilter: Boolean,
      calculationMethodField: String,

      modalId: String,
      modalIdPrevious: Object,
      currentId: String,
  },
  setup(props, {emit}) {

    const { initComponent, componentData, transferData, gateway, transferStatus, sentTransfer, loadingButton } = useShipmentLines();

    initComponent({
      componentName: props.componentName,
      dispatchActions: props.dispatchActions,
      filters: { init: true, get: '' },
      currentId: props.currentId,
      currentModalId: props.modalId,
      calculationMethodField: props.calculationMethodField
    });

    return {
      componentData,
      transferData,
      gateway,
      transferStatus,
      sentTransfer,
      loadingButton
    };
  }
});
